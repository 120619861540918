<template>
    <div>
        <a target="_blank" rel="noopener noreferrer" class="mdc-icon-button material-icons"
           onClick="javascript:window.open('mailto:contact@anlikerentertainment.com', 'mail');event.preventDefault()"
           href="mailto:contact@anlikerentertainment.com">
            <i class="material-icons">
                email
            </i>
        </a>
    </div>
</template>

<script>

    import {MDCRipple} from '@material/ripple';

    export default {
        name: 'LinkFooter',
        mounted: function () {
            const iconButtonRipple = new MDCRipple(document.querySelector('.mdc-icon-button'));
            iconButtonRipple.unbounded = true;
        }
    }
</script>

<style scoped lang="scss">

    @import "~@material/icon-button/mdc-icon-button";

</style>
