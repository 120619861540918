<template>
    <div id="app">
        <img alt="aws logo" src="@/assets/logo.png">
        <span v-show="false">Anliker Entertainment</span>
        <ul v-show="false">
            <li>Artist Management & Development</li>
            <li>Tour Booking & Management</li>
            <li>PR, Marketing & Communication Strategy</li>
            <li>Music & Event Promotion</li>
            <li>Live Event Management & Production</li>
        </ul>
        <LinkFooter/>
    </div>
</template>

<script>

    import LinkFooter from "./components/LinkFooter";

    export default {
        name: 'app',
        components: {LinkFooter},
    }
</script>

<style lang="scss">
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    img {
        max-width: 50%;
        height: auto;
    }

</style>
