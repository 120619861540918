import Vue from 'vue'
import App from './App.vue'
import firebase from 'firebase'

Vue.config.productionTip = false

var firebaseConfig = {
    apiKey: "AIzaSyCmkarEBf3rRULQoI90fjcRlTe7VBx4QnQ",
    authDomain: "aws-ae-home.firebaseapp.com",
    databaseURL: "https://aws-ae-home.firebaseio.com",
    projectId: "aws-ae-home",
    storageBucket: "",
    messagingSenderId: "318864845434",
    appId: "1:318864845434:web:4db71f6a85723732"
};
firebase.initializeApp(firebaseConfig);

new Vue({
  render: h => h(App),
}).$mount('#app')
